<template>
	<div class="wrap">
		<myHead></myHead>
		<div class="wrap_body" v-loading="isLoading">
			<div class="center">
				<div class="page_links">
					当前位置：
					<el-breadcrumb separator-class="el-icon-arrow-right">
						<el-breadcrumb-item :to="{ path: '/uc_after' }">售后管理</el-breadcrumb-item>
						<el-breadcrumb-item>售后详情</el-breadcrumb-item>
					</el-breadcrumb>
				</div>
				<div class="order_status">
					<div class="status_wrap">
						<p class="txt">当前售后状态</p>
						<p class="status">{{detail.return_statu_name}}</p> 
					</div>
					<div class="detail_wrap">
						<el-descriptions labelClassName="la_style" contentClassName="con_style" :colon="false">
							<el-descriptions-item label="订单号">{{detail.order_info.dd_order_id}}</el-descriptions-item>
							<el-descriptions-item label="下单时间">{{detail.order_info.create_time}}</el-descriptions-item>							
							<el-descriptions-item label="付款时间">
								{{detail.order_info.pay_time}}
							</el-descriptions-item>
							<el-descriptions-item label="完成时间">
								{{detail.order_info.finish_time}}
							</el-descriptions-item>
							<el-descriptions-item></el-descriptions-item>
						</el-descriptions>
					</div>
				</div>
				<div class="order_infos">
					<div class="info_con" v-if="detail.order_address">
						<el-descriptions title="收货信息" labelClassName="la_style" contentClassName="con_style" :colon="false" :column=1 >
							<el-descriptions-item label="收货人">
							{{detail.order_address.mask_name}}
							<el-tooltip class="item" effect="dark" content="点击查看" placement="top">
								<i class="iconfont el-icon-view" @click="showAddressInfo()"></i>
							</el-tooltip>
							</el-descriptions-item>
							<el-descriptions-item label="手机号码">{{detail.order_address.mask_phone}}</el-descriptions-item>
							<el-descriptions-item label="收货地址">{{detail.order_address.province}}{{detail.order_address.city}}{{detail.order_address.town}}{{detail.order_address.mask_detail}}</el-descriptions-item>
						</el-descriptions>
					</div>
					<div class="info_con">
						<el-descriptions title="订单物流信息" labelClassName="la_style" contentClassName="con_style" :colon="false" :column="1">
							<template slot="extra">
								<div class="pack_btn">
								</div>
							</template>
							<template >
								<el-descriptions-item label="快递公司">{{ detail.delivery_info.company }}</el-descriptions-item>
								<el-descriptions-item label="发货时间">{{ detail.delivery_info.create_time }}</el-descriptions-item>
								<el-descriptions-item label="商品信息">{{ detail.delivery_info.product_name }} </el-descriptions-item>
								<el-descriptions-item label="">{{ detail.delivery_info.sku_specs }}</el-descriptions-item>
								<el-descriptions-item label="物流单号">
									{{ detail.delivery_info.logistics_code }} 
									<span class="red" style="margin-left: 10px;" @click="showLogistics(detail.delivery_info.logistics_code)">查看物流</span>
								</el-descriptions-item>
							</template>	
						</el-descriptions>
					</div>
				</div>
				<div class="order_infos">
                    
					<div class="info_con">
                        <p class="txt_aftersale">抖店售后信息</p>
						<el-descriptions :title="detail.after_info.aftersale_status_text" labelClassName="la_style" contentClassName="con_style" 
						:colon="false">					
							<el-descriptions-item label="售后编码">{{detail.after_info.aftersale_id}}</el-descriptions-item>
							<el-descriptions-item label="售后类型">{{detail.after_info.aftersale_type_text}}</el-descriptions-item>
							<el-descriptions-item label="申请时间">{{detail.after_info.apply_time}}</el-descriptions-item>
						</el-descriptions>
						<div class="info_flex">
							<div class="info_left">
								<el-descriptions title="收货信息" labelClassName="la_style" contentClassName="con_style" :colon="false" :column=1>
									<el-descriptions-item label="收货人">
										{{detail.order_address.mask_name}}
										<el-tooltip class="item" effect="dark" content="点击查看" placement="top">
											<i class="iconfont el-icon-view" @click="showAddressInfo()"></i>
										</el-tooltip>
									</el-descriptions-item>
									<el-descriptions-item label="手机号码">{{detail.order_address.mask_phone}}</el-descriptions-item>
									<el-descriptions-item label="收货地址">{{detail.order_address.province}}{{detail.order_address.city}}{{detail.order_address.town}}{{detail.order_address.mask_detail}}</el-descriptions-item>
								</el-descriptions>
							</div>
							<div class="info_right">
								<el-descriptions title="订单物流信息" labelClassName="la_style" contentClassName="con_style" :colon="false" :column="1">
									<template slot="extra">
										<div class="pack_btn"></div>
									</template>
									<template>
										<el-descriptions-item label="快递公司">{{ detail.return_logistics.return_logistics_company_name }}</el-descriptions-item>
										<el-descriptions-item label="发货时间">{{ detail.return_logistics.return_logistics_time}}</el-descriptions-item>
										<el-descriptions-item label="商品信息">{{ detail.return_logistics.product_name}}</el-descriptions-item>
										<el-descriptions-item label="">{{ detail.return_logistics.sku_specs }}</el-descriptions-item>
										<el-descriptions-item label="物流单号">
											{{ detail.return_logistics.return_logistics_code }}
											<span class="red" style="margin-left: 10px;" @click="showLogistics(detail.return_logistics.return_logistics_code)">查看物流</span>
										</el-descriptions-item>
									</template>
								</el-descriptions>
							</div>
						</div>
						
					</div>
				</div> 
				<div class="order_infos" v-if="detail.return_statu_name=='待处理'" >
					<div class="info_con">
						<div class="info_header">
							<span class="title">转寄信息</span>
							<span class="add-btn" @click="showTransferDialog">添加转寄信息</span>
						</div>
						<div class="info_content" v-if="detail.afterSalesForward && detail.afterSalesForward.length">
							<div class="info_item" v-for="(item, index) in detail.afterSalesForward" :key="item.id">
								<div class="info_row">
									<span>订单编号：{{detail.order_info.dd_order_id}}</span>
									<span class="ml20">售后单号：{{item.aftersale_id}}</span>
								</div>
								<div class="info_row">
									<span>快递公司：{{item.log_name}}</span>
									<span class="ml20">转寄单号：{{item.forward_no}}</span>
                                    <span class="red" style="margin-left: 10px;" @click="showLogistics(item.code)">查看物流</span>
									<span class="ml20">商品信息：{{item.goods_no}}</span>

								</div>
								<div class="divider" v-if="index !== detail.afterSalesForward.length - 1"></div>
							</div>
						</div>
						<div class="no-data" v-else>
							暂无转寄信息
						</div>
					</div>
				</div>
				<div class="order_infos" v-if="detail.return_statu_name=='已完成不退款'||detail.return_statu_name=='异常件'" >
					<div class="info_con">
						<div class="info_header" v-if="detail.return_shipping!=''">
							<span class="title">回寄信息</span>
							<span class="add-btn" @click="showreturnDialog" v-if="detail.return_shipping==''">添加回寄信息</span>
						</div>
						<div class="info_content" v-if="detail.return_shipping!=''">
							<div class="info_item" style="border-bottom: 0;">
								<div class="info_row">
									<span>订单编号：{{ detail.return_shipping.dd_order_id }}</span>
									<span class="ml20">售后单号：{{ detail.return_shipping.aftersale_id  }}</span>
								</div>
								<div class="info_row">
									<span>收货信息</span>
									<span class="ml20">收货人：{{ detail.return_shipping.consignee }}</span>
									<span class="ml20">手机号码：{{detail.return_shipping.phone}}</span>
									<div>地址：{{ detail.return_shipping.address }}</div>                                 
								</div>
								
							</div>
						</div>
						<div class="info_header" style="border-bottom: 0;" v-if="detail.return_shipping!=''">
							<span class="title">仓库回寄回物流信息</span>
						</div>
						<div class="info_content" v-if="detail.return_shipping!=''">
							<div class="info_item" >
								<div class="info_row">
									<span>快递公司:{{ detail.return_shipping.return_logistics_company_name }}</span>
									<span class="ml20">物流单号：{{ detail.return_shipping.return_logistics_code }}</span>
									<span class="red" style="margin-left: 10px;" @click="showLogistics(detail.return_shipping.return_logistics_code)">查看物流</span>
								</div>
								<div class="info_row">
									<span>发货时间：{{ detail.return_shipping.return_time }}</span>								
									<div>商品信息：{{ detail.return_shipping.product_name }}</div>                                 
								</div>
								
							</div>
						</div>
						<div class="no-data" v-else>
							暂无回寄信息
						</div>
					</div>
				</div>
                <div class="order_goods">
                    <p class="txt_aftersale">商品信息</p>
                    <el-steps :active="detail.return_statu" finish-status="success" align-center class="custom-steps">
                        <el-step title="退货商品送至仓库"></el-step>
                        <el-step title="仓库拆包处理"></el-step>
                        <el-step title="商品退回至档口"></el-step>
                        <el-step title="星好货售后结束"></el-step>
                    </el-steps>
                </div>
				<div class="order_goods">
					<p class="fb">商品信息</p>
					<div class="table_wrap">
						<el-table :data="detail.product">
							<el-table-column label="货品信息" width="" prop="scope">
								<template slot-scope="scope">
									<div class="goods_wrap">
										<div class="img_box"><img class="coverimg" :src="scope.row.product_pic" /></div>
										<div class="con_box">
											<p class="name">{{scope.row.product_name}}</p>
											<p class="color">{{scope.row.spec_value}}</p>
											<!-- <p class="bianma">供应商：{{scope.row.supplier.nickname}}</p> -->
										</div>
									</div>
								</template>
							</el-table-column>
							<el-table-column label="售价/数量" prop="scope" width="140" class="c_price">
								<template slot-scope="scope">
									<p>{{scope.row.item_amount}}</p>
									<p class="num">x{{scope.row.item_num}}</p>
								</template>
							</el-table-column>
							<el-table-column label="售后状态" prop="scope" width="150">
								<template >
									<p>{{detail.return_statu_name}}</p>
									
								</template>
							</el-table-column>
							<el-table-column label="采购成本" prop="order_amount" width="200">
								<template slot-scope="scope">
									<p>{{scope.row.refund_amount}}</p>
									<p class="red" v-if="scope.row.deduction_money>0">抵扣金抵扣：{{scope.row.deduction_money}}</p>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>
			
			</div>
		</div>
		<!-- 收货地址信息弹窗 -->
		<orderAddress @close="dialog.addressDialog = false" :isLoading.sync="isLoading" :show.sync="dialog.addressDialog" :order_id.sync="detail.order_address.order_id"></orderAddress>
		<!-- 售后详情弹窗 -->
		<el-dialog title="售后详情" :visible.sync="dialog.aftersaleDialog" width="60%" center >
			<orderAfterSales @close="dialog.aftersaleDialog = false" @complete="init()" :isLoading.sync="isLoading" :show.sync="dialog.aftersaleDialog" :info.sync="order_product"></orderAfterSales>
		</el-dialog>
		<el-drawer
			title="快递详情"
			:visible.sync="table"
			direction="rtl"
			size="50%">
			<el-table 
				:data="gridData" 
				style="width: 100%"
				:header-cell-style="{background:'#f5f7fa'}"
				highlight-current-row>
				<el-table-column 
					property="ftime" 
					label="时间" 
					width="180">
				</el-table-column>
				<el-table-column 
					property="context" 
					label="物流信息">
				</el-table-column>
			</el-table>
		</el-drawer>
		<!-- 转寄信息弹窗 -->
		<TransferInfo 
			:show.sync="dialog.transferDialog"
			:info="{
				order_id: detail.order_info.dd_order_id,
				aftersale_id: detail.after_info.aftersale_id
			}"
			@success="init"
		/>
		<!-- 回寄信息弹窗 -->
		<returnInfo 
			:show.sync="dialog.returnDialog"
			:info="{
				order_id: detail.order_info.dd_order_id,
				aftersale_id: detail.after_info.aftersale_id
			}"
			@success="init"
			/>
		<myFoot></myFoot>
	</div>
</template>

<script>
import Vue from 'vue';
import myHead from '../../components/myHead/myhead';
import myFoot from '../../components/myFoot/myfoot';
import orderAddress from '../../components/orderAddress/orderAddress';
import orderAfterSales from '../../components/orderAfterSales/orderAfterSales';
import TransferInfo from '../../components/transferInfo/transferInfo';
import returnInfo from '../../components/returnInfo/returnInfo';
import { mapActions,mapState,mapMutations } from "vuex";
export default {
	name: "orderDetail",
	data() {
		return {
			order_id: 0,
			status: 0,
			detail: {
				after_info: {
	
				},
				order_info: {
					
				},
				order_address: {
					
				},
				delivery_info: {
					
				},
                return_logistics: {
					
				},
                afterSalesForward: [],
                product: [],
                transfer_info: null
			},
			dialog: {
				addressDialog: false,
				aftersaleDialog: false,
				transferDialog: false,
				returnDialog: false
			},
			isLoading: false,
			delivery_idx: 0,
			order_product: '',
			table: false,
			gridData: [],
		}
	},
	components:{
		myHead,myFoot,orderAddress,orderAfterSales,TransferInfo,returnInfo
	},
	created(){
		this.order_id = parseInt(this.$route.query.order_id)
		this.status = parseInt(this.$route.query.status)
	},
	mounted(){
		this.init()
	},
	methods: {
		...mapActions({
			getAfterSaleDetail:"ucenter/getAfterSaleDetail",    
			queryDelivery: "ucenter/queryDelivery",
			logisticsForwarding: "ucenter/logisticsForwarding",
			returnApplication: "ucenter/returnApplication"
		}),
		init(){
			var param = {aftersale_id:this.order_id,type:this.status}
			this.isLoading = true
			this.getAfterSaleDetail({data:param,success:(res)=>{
				if(res.code == 200){
					this.detail = res.data
				}else{
					this.$message({message: res.msg,type: 'error'});
				}
				this.isLoading = false
			}})
		},
		showAddressInfo(){
			this.dialog.addressDialog = true
		},
		deliveryChange(index){
			this.delivery_idx = index 
		},
		showAfterSaleInfo(order_product){
			this.order_product = order_product
			this.dialog.aftersaleDialog = true
		},
		showLogistics(num) {
			if(!num) {
				this.$message.warning('暂无物流信息')
				return
			}
			this.queryDelivery({
				data: { num },
				success: (res) => {
					if(res.code === 200 && res.data.data) {
						this.gridData = res.data.data.map(item => ({
							ftime: item.ftime,
							context: item.context
						}))
						this.table = true
					} else {
						this.$message.error(res.msg || '获取物流信息失败')
					}
				}
			})
		},
		showTransferDialog() {
			this.dialog.transferDialog = true;
		},
		showreturnDialog() {
			this.dialog.returnDialog = true;
		},
		formatTime(timestamp) {
			if(!timestamp) return '-'
			const date = new Date(timestamp * 1000)
			const year = date.getFullYear()
			const month = (date.getMonth() + 1).toString().padStart(2, '0')
			const day = date.getDate().toString().padStart(2, '0')
			const hour = date.getHours().toString().padStart(2, '0')
			const minute = date.getMinutes().toString().padStart(2, '0')
			const second = date.getSeconds().toString().padStart(2, '0')
			return `${year}-${month}-${day} ${hour}:${minute}:${second}`
		}
	}
}
</script>

<style scoped>
.order_status{margin-top: 30px;width: 100%;height: auto;box-sizing: border-box;padding: 30px 40px 20px;background: #fff;}
.order_status .status_wrap{line-height: 1;padding-bottom: 30px;border-bottom: 1px solid #eee;}
.order_status .status_wrap .txt{font-size: 14px;color: #999999;}
.txt_aftersale{font-size: 14px;color: #999999; padding-bottom: 10px;}
.order_status .status_wrap .status{font-size: 24px;margin-top: 16px;}
.order_status .detail_wrap{padding-top: 30px;}
.order_infos{margin-top: 15px;width: 100%;height: auto;box-sizing: border-box;padding: 30px 0;background: #fff;display: flex;}
.order_infos .info_con{flex: 1;margin-left: 40px;width: 0;box-sizing: border-box;border-right: 1px solid #eee;}
.order_infos .info_con:last-child{border-right: none;}
.order_infos .info_con .goods{position: relative;width: 300px;height: auto;display: inline-block;margin-right: 5px;}
.order_infos .info_con .goods .cover_image{width:60px;height:60px;border: 1px solid #f5f5f5;}
.order_infos .info_con .goods .num{font-size: 12px;position: absolute;bottom:0px;right: 0px;color: #333;background-color: rgba(255,255,255,0.8);padding: 2px 4px;}
.order_infos .info_con .pack_btn{margin-right: 20px;}
.order_goods{margin-top: 15px;width: 100%;height: auto;box-sizing: border-box;padding: 30px 40px;background: #fff;line-height: 1;}
.order_goods .table_wrap{margin-top: 30px;font-size: 12px;}
.goods_wrap{width: 100%;box-sizing: border-box;display: flex;}
.goods_wrap .img_box{width: 48px;height: 48px;overflow: hidden;background: #eee;}
.goods_wrap .img_box>img{width: 100%;height: 100%;object-fit: cover;}
.goods_wrap .con_box{max-width: 80%;margin-left: 10px;text-align: left;}
.goods_wrap .con_box .name{line-height: 16px;color: #333;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden;}
.goods_wrap .con_box .color{color: #999999;margin-top: 10px;line-height: 1;}
.goods_wrap .con_box .bianma{color: #999999;margin-top: 6px;line-height: 1;}
.order_goods .total_txt{display: flex;justify-content: flex-end;flex-direction: column;font-size: 12px;color: #999999;align-items: flex-end;padding: 26px 0 15px;}
.order_goods .total_txt span{font-size: 16px;color: #333;}
.order_goods .total_txt span>em{color: var(--red);font-weight: bold;}
.order_goods .total_txt>p:nth-child(2){margin-top: 10px;}
.red{color: var(--red);cursor: pointer;}

.order_records{margin-top: 15px;width: 100%;height: auto;box-sizing: border-box;padding: 30px 40px;background: #fff;line-height: 1;}
.records_wrap{margin-top: 25px;}
.records_wrap .record_con{padding-left: 42px;position: relative;}
.record_con::before{width: 32px;height: 32px;border-radius: 50%;display: flex;justify-content: center;align-items: center;position: absolute;top: 0;left: 0;z-index: 2;font-size: 12px;font-weight: bold}
.record_con.c{padding-bottom: 13px;}
.record_con.c::before{content: '买家';background: #ffecee;color: var(--red);}
.record_con.b::before{content: '商家';background: #f0ffee;color: #0e9f1f;}
.record_con.c::after{content: '';width: 2px;height: 68%;background: #eee;position: absolute;left: 15px;top: 40px;}
.record_con .txt{font-size: 14px;line-height: 32px;}
.record_con .txt_wrap{margin-top: 12px;width: 900px;}

.info_flex {
    display: flex;
    margin: 20px 0;
    border-bottom: 1px solid #eee;
    padding-bottom: 20px;
}
.info_flex .info_left {
    flex: 1;
    border-right: 1px solid #eee;
    padding-right: 20px;
}
.info_flex .info_right {
    flex: 1;
    padding-left: 20px;
}
.info_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 15px;
    border-bottom: 1px solid #eee;
}
.info_header .title {
    font-size: 14px;
    color: #333;
}
.info_header .add-btn {
    color: var(--red);
    cursor: pointer;
    font-size: 14px;    
    padding-right: 20px;
}
.info_content {
    padding: 5px 0;
}
.info_content .info_row {
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 10px;
}
.info_content .info_row:last-child {
    margin-bottom: 0;
}
.info_content .info_row .ml20 {
    margin-left: 20px;
}
.no-data {
    color: #999;
    font-size: 14px;
    text-align: center;
    padding: 20px 0;
}
.info_item {
    padding: 15px 0;
    margin-right: 35px;
    border-bottom: 1px solid #eee;
}
.divider {
    height: 1px;
    background: #eee;
    margin: 15px 0;
}
</style>
<style>
.page_links{line-height: 1;font-size: 14px;color: #999999;display: flex;margin-top: 25px;}
.page_links .el-breadcrumb__inner{font-weight: bold !important;color: #333 !important;}
.page_links .el-breadcrumb__inner.is-link{font-weight: initial !important;color: #999 !important;}
.page_links .el-breadcrumb__inner.is-link:hover{color: #f0423f !important;text-decoration: underline;}
.el-table .el-table_1_column_2 .cell .num{color: #999;}
.el-table th.el-table__cell{background: #fff6f7;border-bottom: none !important;color: #666666;padding: 12px 0;}
.el-table .el-table__cell{vertical-align: top;padding: 24px 0;}
.el-table .cell{padding: 0 20px;text-align: center;font-size: 12px;color: #333;}
.el-table th.el-table__cell>.cell{padding: 0 20px;}
.el-table .el-table_1_column_1 .cell{text-align: left;}
.el-table .el-table_1_column_2 .cell{text-align: right;}
.el-table th.el-table_1_column_1 .cell{text-align: left;}
.el-table th.el-table_1_column_2 .cell{text-align: right;}
.con_style{color: #333;}
.la_style{color: #999;width: 70px;}
.el-descriptions{font-size: 12px;}
.el-descriptions__title{color: #333;}
.el-icon-view{cursor: pointer;}

/*red border color*/
.el-button.is-plain:focus, .el-button.is-plain:hover ,.el-checkbox__inner:hover ,.el-checkbox__input.is-focus .el-checkbox__inner,.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner, .el-pagination__jump .el-input__inner:focus{border-color: var(--red);}

/*red color*/
.el-button.is-plain:focus, .el-button.is-plain:hover, .el-checkbox__input.is-checked+.el-checkbox__label, .el-pager li.active ,.el-pagination button:hover ,.el-pager li:hover{color: var(--red);background-color: #fff;}

/*red background color*/
.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner{background-color: var(--red);}

/* 自定义步骤条样式 */
.custom-steps {
    padding: 20px 40px;
    margin: 20px 0;
}

.custom-steps .el-step__head.is-finish {
    color: var(--red);
    border-color: var(--red);
}

.custom-steps .el-step__title.is-finish {
    color: var(--red);
}

.custom-steps .el-step__line {
    background-color: #ffd4d4;
}

.custom-steps .el-step__head.is-process {
    color: var(--red);
    border-color: var(--red);
}

.custom-steps .el-step__title.is-process {
    color: var(--red);
}

.custom-steps .el-step__head.is-wait {
    color: #c0c4cc;
    border-color: #c0c4cc;
}

.custom-steps .el-step__title.is-wait {
    color: #c0c4cc;
}

/* 调整步骤条间距和对齐 */
.custom-steps .el-step {
    flex-basis: 25%;
    min-width: 25%;
}
</style>