<template>
    <el-dialog title="回寄信息" :visible.sync="dialogVisible" width="600px" :center="false">
      <div class="transfer-form">
        <div class="form-row order-info">
          <span class="label">关联订单编号：</span>
          <span class="value">{{info.order_id}}</span>
          <span class="label ml20">关联售后单号：</span>
          <span class="value">{{info.aftersale_id}}</span>
        </div>
        <div class="form-row">
          <span class="label">收货人姓名：</span>
          <el-input v-model="form.consignee" placeholder="请输入收货人姓名" style="width: 180px"></el-input>
          <span class="label ml20">收货人电话：</span>
          <el-input v-model="form.phone" placeholder="请输入收货人电话" style="width: 180px"></el-input>
        </div>
        <div class="form-row">
          <span class="label">收货人地址：</span>
          <el-input v-model="form.address" placeholder="请输入收货人地址" style="width: 480px"></el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <div class="btn-group">
          <el-button @click="handleCancel">取 消</el-button>
          <el-button type="primary" @click="handleSubmit">提 交</el-button>
        </div>
      </span>
    </el-dialog>
  </template>
  
  <script>
  export default {
    name: 'ReturnInfo',
    props: {
      show: {
        type: Boolean,
        default: false
      },
      info: {
        type: Object,
        default: () => ({})
      }
    },
    data() {
      return {
        dialogVisible: false,
        form: {
          aftersale_id: '',
          consignee: '',
          phone: '',
          address: ''
        },
        companies: []
      }
    },
    created() {
      this.getLogisticsCompanies()
    },
    watch: {
      show(val) {
        this.dialogVisible = val
      },
      dialogVisible(val) {
        if(!val) {
          this.$emit('update:show', false)
        }
      },
      info: {
        handler(val) {
          if(val) {
            this.form.aftersale_id = val.aftersale_id
           
          }
        },
        immediate: true
      }
    },
    methods: {
      getLogisticsCompanies() {
        this.$store.dispatch('ucenter/getLogistics', {
          data: {},
          success: (res) => {
            if(res.code === 200) {
              this.companies = res.data.map(item => ({
                label: item.log_name,
                value: item.code
              }))
            }
          }
        })
      },
      handleCancel() {
        this.dialogVisible = false
      },
      handleSubmit() {
        // 表单验证
        if (!this.form.consignee) {
          this.$message.error('请输入收货人姓名')
          return
        }
        if (!this.form.phone) {
          this.$message.error('请输入收货人电话')
          return
        }
        if (!this.form.address) {
          this.$message.error('请输入收货人地址')
          return
        }
  
        this.$confirm('是否确认提交退货申请？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$store.dispatch('ucenter/returnLogistics', {
            data: this.form,
            success: (res) => {
              if (res.code === 200) {
                this.$message.success('提交成功')
                this.dialogVisible = false
                this.$emit('success')
                this.form = {
                  order_id: '',
                  shop_id: '',
                  consignee: '',
                  phone: '',
                  address: ''
                }
              } else {
                this.$message.error(res.msg || '提交失败')
              }
            },
            error: () => {
              this.$message.error('提交失败,请重试')
            }
          })
        })
      }
    }
  }
  </script>
  
  <style scoped>
  .transfer-form {
    padding: 10px 20px;
    min-width: 560px;
  }
  .form-row {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
  }
  .order-info {
    white-space: nowrap;
  }
  .label {
    color: #999;
    display: inline-block;
    min-width: 90px;
    flex-shrink: 0;
  }
  .ml20 {
    margin-left: 20px;
  }
  .value {
    color: #333;
    margin-right: 20px;
  }
  .btn-group {
    text-align: right;
  }
  .btn-group .el-button + .el-button {
    margin-left: 10px;
  }
  
  /* 覆盖el-dialog样式 */
  :deep(.el-dialog__header) {
    padding: 15px 20px;
    border-bottom: 1px solid #eee;
  }
  :deep(.el-dialog__body) {
    padding: 20px 0;
  }
  :deep(.el-dialog__footer) {
    padding: 10px 20px;
    border-top: 1px solid #eee;
  }
  </style> 