<template>
  <el-dialog title="转寄信息" :visible.sync="dialogVisible" width="600px" :center="false">
    <div class="transfer-form">
      <div class="form-row order-info">
        <span class="label">关联订单编号：</span>
        <span class="value">{{info.order_id}}</span>
        <span class="label ml20">关联售后单号：</span>
        <span class="value">{{info.aftersale_id}}</span>
      </div>
      <div class="form-row">
        <span class="label">转寄物流公司：</span>
        <el-select v-model="form.company" placeholder="请选择" style="width: 180px">
          <el-option v-for="item in companies" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <span class="label ml20">转寄单号：</span>
        <el-input v-model="form.logistics_code" placeholder="请输入转寄单号" style="width: 180px"></el-input>
      </div>
      <div class="form-row">
        <span class="label">转寄商品信息：</span>
        <el-input v-model="form.product_info" placeholder="请输入商品编码" style="width: 480px"></el-input>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <div class="btn-group">
        <el-button @click="handleCancel">取 消</el-button>
        <el-button type="primary" @click="handleSubmit">提 交</el-button>
      </div>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'TransferInfo',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    info: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      dialogVisible: false,
      form: {
        company: '',
        logistics_code: '',
        product_info: ''
      },
      companies: []
    }
  },
  created() {
    this.getLogisticsCompanies()
  },
  watch: {
    show(val) {
      this.dialogVisible = val
    },
    dialogVisible(val) {
      if(!val) {
        this.$emit('update:show', false)
      }
    }
  },
  methods: {
    getLogisticsCompanies() {
      this.$store.dispatch('ucenter/getLogistics', {
        data: {},
        success: (res) => {
          if(res.code === 200) {
            this.companies = res.data.map(item => ({
              label: item.log_name,
              value: item.code
            }))
          }
        }
      })
    },
    handleCancel() {
      this.$confirm('是否确认取消？取消将不保存已填写信息', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.dialogVisible = false
      })
    },
    handleSubmit() {
      // 表单验证
      if (!this.form.company) {
        this.$message.error('请选择转寄物流公司')
        return
      }
      if (!this.form.logistics_code) {
        this.$message.error('请输入转寄单号')
        return
      }
      if (!this.form.product_info) {
        this.$message.error('请输入商品编码')
        return
      }

      this.$confirm('是否确认提交转寄信息？', '提示', {
        confirmButtonText: '确定', 
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const selectedCompany = this.companies.find(item => item.value === this.form.company)
        
        const params = {
          aftersale_id: this.info.aftersale_id,
          forward_no: this.form.logistics_code,
          code: this.form.company,
          log_name: selectedCompany ? selectedCompany.label : '',
          goods_no: this.form.product_info
        }
        
        this.$store.dispatch('ucenter/logisticsForwarding', {
          data: params,
          success: (res) => {
            if (res.code === 200) {
              this.$message.success('提交成功')
              this.dialogVisible = false
              this.$emit('success')
              this.form = {
                company: '',
                logistics_code: '',
                product_info: ''
              }
            } else {
              this.$message.error(res.msg || '提交失败')
            }
          },
          error: () => {
            this.$message.error('提交失败,请重试')
          }
        })
      })
    }
  }
}
</script>

<style scoped>
.transfer-form {
  padding: 10px 20px;
  min-width: 560px;
}
.form-row {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}
.order-info {
  white-space: nowrap;
}
.label {
  color: #999;
  display: inline-block;
  min-width: 90px;
  flex-shrink: 0;
}
.ml20 {
  margin-left: 20px;
}
.value {
  color: #333;
  margin-right: 20px;
}
.btn-group {
  text-align: right;
}
.btn-group .el-button + .el-button {
  margin-left: 10px;
}

/* 覆盖el-dialog样式 */
:deep(.el-dialog__header) {
  padding: 15px 20px;
  border-bottom: 1px solid #eee;
}
:deep(.el-dialog__body) {
  padding: 20px 0;
}
:deep(.el-dialog__footer) {
  padding: 10px 20px;
  border-top: 1px solid #eee;
}
</style> 